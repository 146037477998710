export const menuItems = [
    {
        id: 1,
        label: "menuitems.menu.text",
        isTitle: true
    },
    {
        id: 2,
        label: "Pedidos del día",
        icon: "uil-home-alt",
        badge: {
            variant: "primary",
            text: "menuitems.dashboard.badge"
        },
        link: "/",
        roles: [2,3]
    },
  
    {
        id: 3,
        label: "Top 10",
        icon: "uil-award",
        link: "/topten",
        roles: []
    },
    {
        id: 4,
        label: "Sliders",
        icon: "uil-image",
        link: "/sliders",
        roles: []
    },
    /*{
        id: 5,
        label: "Eventos",
        icon: "uil-store",
        link: "/arreglos",
    },*/
  
    {
        id: 6,
        label: "Arreglos",
        icon: "mdi mdi-flower-outline",
        link: "/arreglos",
        roles: [2]
    },
  
    {
        id: 7,
        label: "Categorias",
        icon: "uil-apps",
        link: "/categorias",
        roles: []
    },
  
    {
        id: 8,
        label: "Tipos",
        icon: "mdi mdi-flower-tulip-outline",
        link: "/tipos",
        roles: []
    },
  
    {
        id: 9,
        label: "Seo General",
        icon: "uil-analysis",
        link: "/seo-general",
        roles: []
    },
    {
        id: 10,
        label: "Zonas envío",
        icon: "uil-map-marker-alt",
        link: "/zonas",
        roles: []
    },
    {
        id: 11,
        label: "Clientes",
        icon: "mdi mdi-account-group",
        link: "/clientes",
        roles: [2]
        
    },
    {
        id: 12,
        label: "Pedidos",
        icon: "uil-invoice",
        link: "/pedidos",
        roles: [2]
       
    },
    
    {
        id: 13,
        label: "Usuarios",
        icon: "uil-user-circle",
        link: "/usuarios",
        roles: []
        
    },
   
];

